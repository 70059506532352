@import "styles/sizes";

.art {
  &__slider-wrapper {
    width: 500px;

    margin: 0 auto;

    @include s {
      width: 100%;

      margin: 6px auto 38px auto;
    }

    @include tl {
      width: 100%;
    }
  }

  &__slider {
    display: flex;
    align-items: center;

    &-option-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 50%;

      cursor: pointer;

      &_active {
        .art__slider-option {
          color: black;
        }
      }
    }

    &-option {
      font-weight: 700;
      font-size: 36px;
      line-height: 36px;
      /* identical to box height, or 100% */

      letter-spacing: -0.03em;

      color: rgba(0, 0, 0, 0.3);

      @include s {
        font-size: 24px;
        line-height: 32px;
      }
    }
  }

  &__subtitle {
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    text-align: center;
    letter-spacing: -0.03em;
    color: black;

    max-width: 560px;
    margin: 60px auto 42px auto;

    @include s {
      margin: 40px auto 32px auto;
    }
  }

  &__body {
    display: block;

    border-top: 1px solid rgba(0, 0, 0, 0.1);

    padding-top: 40px;
  }

  .art-block {
    max-width: 500px;
    margin: 0 auto;

    @include s {
      width: 100%;
    }

    &__wrapper {
      padding-bottom: 39px;
      margin-bottom: 39px;

      &:not(:last-child) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }

      @include s {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__image-wrapper {
      width: 447px;
      height: 187px;

      margin: 0 auto;

      overflow: hidden;

      @include s {
        width: 100%;
      }

      @include tl {
        width: 100%;
      }
    }

    &__image {
      width: 100%;
      height: 100%;

      object-position: center;
      object-fit: cover;
    }

    &__number {
      font-weight: 700;
      font-size: 102px;
      line-height: 102px;
      letter-spacing: -0.03em;
      color: #FEFEFE;
      text-align: center;

      position: relative;
      z-index: 2;
      margin-top: -57px;

      @include s {
        font-size: 74px;
        line-height: 74px;

        margin-top: -42px;
      }
    }

    &__title {
      font-weight: 700;
      font-size: 48px;
      line-height: 58px;
      letter-spacing: -0.03em;
      color: black;
      text-align: center;

      margin-top: 11px;

      @include s {
        font-size: 32px;
        line-height: 42px;
      }
    }

    &__description {
      font-weight: 500;
      font-size: 14px;
      line-height: 28px;
      text-align: center;
      letter-spacing: -0.03em;
      color: black;

      margin-top: 21px;

      @include s {
        font-size: 14px;
        line-height: 28px;

        padding: 0 10px;
      }
    }
  }
}
@font-face {
  font-family: 'MomaSansWeb';
  src: url('/assets/fonts/MomaSansWeb/MoMASansWebBold.eot');
  src: url('/assets/fonts/MomaSansWeb/MoMASansWebBold.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/MomaSansWeb/MoMASansWebBold.woff2') format('woff2'),
  url('/assets/fonts/MomaSansWeb/MoMASansWebBold.woff') format('woff'),
  url('/assets/fonts/MomaSansWeb/MoMASansWebBold.ttf') format('truetype');
  //url('/fonts/MomaSansWeb/MoMASansWebBold.svg#MomaSansWebBold') format('svg'),
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MomaSansWeb';
  src: url('/assets/fonts/MomaSansWeb/MoMASansWebMedium.eot');
  src: url('/assets/fonts/MomaSansWeb/MoMASansWebMedium.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/MomaSansWeb/MoMASansWebMedium.woff2') format('woff2'),
  url('/assets/fonts/MomaSansWeb/MoMASansWebMedium.woff') format('woff'),
  url('/assets/fonts/MomaSansWeb/MoMASansWebMedium.ttf') format('truetype');
  //url('/fonts/MomaSansWeb/MoMASansWebMedium.svg#MoMASansWebMedium') format('svg'),
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@import "styles/sizes";

.contact-page {
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin-top: -100px;

  height: 100%;

  @include s {
    display: block;

    margin-top: 7px;
  }

  .contact-block {
    margin-bottom: 52px;

    &_follow {
      margin-top: 203px;

      @include s {
        margin-top: 139px;
      }
    }

    &__title {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.5);

      margin-bottom: 20px;

      @include s {
        font-size: 18px;
        line-height: 18px;
      }
    }

    &__text {
      font-weight: 700;
      font-size: 24px;
      line-height: 24px;
      color: black;

      @include s {
        font-size: 18px;
        line-height: 18px;
      }
    }

    &__social-row {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      @include s {
        margin-top: 28px;
      }
    }

    &__social-icon-wrapper {
      &:not(:last-child) {
        margin-right: 25px;
      }
    }

    &__social-icon {
      width: 30px;
      height: 30px;

      cursor: pointer;
    }
  }
}
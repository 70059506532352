@import "styles/colors";
@import "styles/sizes";

.writing-page {
  max-width: 685px;

  @include s {
    padding-top: 6px;
  }

  .article {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include s {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }

    @include tl {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }

    &_reverse {
      .article {
        &__number-block {
          order: 3;

          @include s {
            order: 1;
          }

          @include tl {
            order: 1;
          }
        }

        &__number {
          text-align: right;
        }

        &__info-block-wrapper {
          order: 1;

          @include s {
            order: 3;
          }

          @include tl {
            order: 3;
          }
        }

        &__article-number {
          @include s {
            text-align: right;
          }

          @include tl {
            text-align: right;
          }
        }

        &__delimiter {
          @include s {
            margin-left: auto;
            margin-right: 0;
          }

          @include tl {
            margin-left: auto;
            margin-right: 0;
          }
        }
      }
    }

    &__wrapper {
      &:not(:last-child) {
        margin-bottom: 77px;

        @include s {
          margin-bottom: 41px;
        }

        @include tl {
          margin-bottom: 20px;
        }
      }
    }

    &__number-block {
      width: 22%;

      order: 1;

      @include s {
        width: 100%;
      }

      @include tl {
        width: 100%;
      }
    }

    &__number {
      font-weight: 700;
      font-size: 82px;
      line-height: 180px;
      letter-spacing: -0.03em;
      color: $grey400;

      position: relative;

      @include s {
        font-size: 64px;
        line-height: 64px;
      }

      @include dsxs {
        font-size: 70px;
      }

      &-text {
        font-weight: 400;
        font-size: 18px;
        line-height: 38px;
        letter-spacing: -0.03em;
        color: black;

        position: relative;
        top: -17px;

        margin-left: -39px;

        @include s {
          font-weight: 500;
          font-size: 16px;
          line-height: 16px;

          top: -11px;
          margin-left: -32px;
        }

        @include dsxs {
          font-size: 16px;
          margin-left: -50px;
        }
      }
    }

    &__delimiter-wrapper {
      width: 9%;
      order: 2;

      @include s {
        width: 100%;

        margin-top: 28px;
        margin-bottom: 29px;
      }

      @include tl {
        width: 100%;

        margin-top: 0;
        margin-bottom: 29px;
      }
    }

    &__delimiter {
      width: 50px;
      height: 2px;

      border: none;

      background-color: black;
    }

    &__info-block-wrapper {
      width: 65%;

      order: 3;

      @include s {
        width: 100%;
      }

      @include tl {
        width: 100%;
      }
    }

    &__info-block {
      max-width: 450px;

      @include s {
        max-width: none;
      }

      @include tl {
        max-width: none;
      }
    }

    &__title {
      font-weight: 700;
      font-size: 36px;
      line-height: 42px;
      letter-spacing: -0.03em;
      color: black;

      margin-bottom: 12px;

      @include s {
        font-size: 32px;
      }
    }

    &__description {
      font-weight: 400;
      font-size: 14px;
      line-height: 28px;
      letter-spacing: -0.03em;
      color: black;
    }
  }
}
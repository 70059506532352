@import "styles/colors";
@import "styles/sizes";

//$menuWidth: 576px;
$menuWidth: 40.2%;

.base-layout {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: flex-start;

  &__menu-wrapper {
    width: $menuWidth;
    height: 100vh;

    @include s {
      width: 100%;
      height: 100%;
    }
  }

  &__content-wrapper {
    width: calc(100% - $menuWidth);
    max-width: 1000px;

    height: 100vh;
    max-height: 100vh;

    overflow-y: scroll;

    //background-color: $grey300;

    padding: 212px 60px 60px 60px;

    @include s {
      background-color: $grey300;

      width: 100%;

      padding: 24px;

      max-height: none;
      height: fit-content;
    }

    &_active {
      @include s {
        max-height: fit-content;

        margin-top: 21px;
        margin-bottom: 9px;
      }
    }

    &_hidden {
      @include s {
        max-height: 0;
        padding: 0;
      }
    }
  }
}
@import "styles/colors";
@import "styles/sizes";

.navigation {
  background-color: $grey200;

  width: 100%;
  height: 100%;

  .menu-block {
    display: flex;
    align-items: center;

    &__wrapper {
      width: 100%;

      padding: 101px 20px 0 120px;

      position: absolute;
      top: 0;
      left: 0;

      @include s {
        margin: 100px 0 99px 0;
        padding: 0 24px;
      }

      @include tl {
        padding-left: 40px;
      }

      @include dsxs {
        padding-left: 60px;
      }
    }

    &__text {
      font-weight: 500;
      font-size: 24px;
      line-height: 38px;
      letter-spacing: -0.03em;
      color: black;
    }

    &__delimiter {
      width: 60px;
      height: 2px;

      background-color: black;

      border: none;

      margin-left: 10px;

      position: relative;

      @include s {
        margin-left: 11px;
        top: 3px;
      }
    }
  }

  &__links-wrapper {
    width: 100%;
    height: 100%;

    max-width: 700px;

    margin: 0 0 0 auto;

    padding: 101px 20px 101px 120px;

    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;

    @include s {
      padding: 0;
      max-width: none;
    }

    @include tl {
      padding-left: 40px;
    }

    @include dsxs {
      padding-left: 60px;
    }
  }

  &__link-menu-wrapper {
    position: relative;
    width: 100%;
    height: fit-content;
    overflow-y: scroll;

    top: 40px;

    @include s {
      top: 0;
      padding-top: 237px;
    }
  }

  &__link-section {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    @include s {
      &:not(:last-child) {
        margin-bottom: 20px;
      }

      &:last-child {
        .navigation__link-wrapper {
          margin-bottom: 20px;
        }

        .base-layout__content-wrapper_active {
          margin-bottom: 0;
        }
      }
    }
  }

  &__link-wrapper {
    width: 100%;
  }

  &__link {
    font-weight: 700;
    font-size: 64px;
    line-height: 72px;
    letter-spacing: -0.03em;
    color: $black30;
    text-align: left;

    transition: color 0.25s ease-in-out;

    &:hover {
      color: $black200;
    }

    &_active {
      color: $black200;
    }

    @include s {
      padding: 0 24px;

      font-size: 36px;
      line-height: 44px;
    }

    @include tl {
      font-size: 44px;
    }

    @include dsxs {
      font-size: 58px;
    }
  }
}
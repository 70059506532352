@import "styles/sizes";

.experience {
  @include s {
    margin-top: 7px;
  }

  &__row {
    display: flex;
    align-items: flex-start;

    &:not(:last-child) {
      margin-bottom: 50px;
    }

    @include s {
      flex-direction: column;
    }

    @include tl {
      flex-direction: column;
    }
  }

  &__section {
    &:not(:last-child) {
      margin-right: 80px;

      @include s {
        margin: 0 0 40px 0;
      }

      @include tl {
        margin: 0 0 40px 0;
      }
    }
  }

  &__title {
    font-weight: 700;
    font-size: 36px;
    line-height: 36px;
    letter-spacing: -0.03em;
    color: black;

    margin-bottom: 36px;

    &_certificate {
      margin-bottom: 12px;
    }

    @include s {
      font-size: 24px;
      line-height: 32px;

      margin-bottom: 30px;
    }
  }

  &__block {
    &:not(:last-child) {
      margin-bottom: 31px;
    }

    &-title {
      font-weight: 700;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: -0.03em;
      color: rgba(0, 0, 0, 0.7);

      margin-bottom: 8px;
    }

    &-option-list {
      list-style: none;
    }

    &-option {
      font-weight: 500;
      font-size: 12px;
      line-height: 24px;
      letter-spacing: -0.03em;
      color: rgba(0, 0, 0, 0.7);

      position: relative;
      padding-left: 11px;

      &:before {
        content: "";

        display: inline-block;

        vertical-align: middle;

        width: 4px;
        height: 4px;

        border-radius: 50%;

        overflow: hidden;

        background-color: #474747;

        position: absolute;
        top: 10px;
        left: -1px;
      }

      &_no-circle {
        padding-left: 0;

        &:before {
          display: none;
        }
      }

      &_note {
        font-size: 14px;

        &:before {
          top: 0;
        }
      }
    }
  }
}
@import "styles/colors";
@import "styles/sizes";

.home-page {
  padding: 0 50px;

  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @include s {
    padding: 6px 0 0 0;

    justify-content: flex-start;
  }

  @include tl {
    padding: 0;
  }

  .swiper {
    width: 100%;
   padding-bottom: 74px;

    @include s {
      padding-bottom: 47px;
    }

    .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
      @include s {
        bottom: 6px;
      }
    }


    &-pagination {

      &-bullet {
        width: 11px;
        height: 11px;

        margin: 0 7.5px !important;

        background-color: $black20;

        vertical-align: middle;

        &-active {
          width: 15px;
          height: 15px;

          margin: 0 8px !important;

          background-color: black;
        }
      }
    }
  }

  &__slide {
    width: 100%;
    height: 100%;

    aspect-ratio: 4 / 3;

    @include s {
      aspect-ratio: 4 / 2.9;
    }
  }

  &__slide-img {
    width: 100%;
    height: 100%;

    object-fit: cover;
    object-position: center;
  }
}
@import "styles/sizes";

.companies {
  padding-left: 100px;

  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;

  margin-top: -100px;

  @include s {
    display: block;

    padding-left: 0;

    margin-top: 9px;
  }

  @include tl {
    padding-left: 0;
  }

  .company {
    &__wrapper {
      &:not(:last-child) {
        margin-bottom: 38px;

        @include s {
          margin-bottom: 39px;
        }
      }

      &:nth-last-child(2) {
        margin-bottom: 22px;

        @include s {
          margin-bottom: 19px;
        }
      }

      @include s {
        display: flex;
        justify-content: center;
      }
    }

    &__image-wrapper {
      width: 200px;
      height: 75px;

      display: block;

      position: relative;

      opacity: 0.3;
      transition: opacity 0.25s ease-in-out;

      &:hover {
        opacity: 1;
      }

      @include s {
        height: 36px;
      }

      &_prive {
        width: 211px;

        @include s {
          width: 140px;
          height: 45px;
        }
      }

      &_prive-reserve {
        width: 267px;
        left: -5px;

        @include s {
          height: 41px;
          left: -1px;
        }
      }

      &_hodl {
        height: 80px;
        width: 350px;

        position: relative;
        left: -7px;

        @include s {
          width: 220px;
          left: 0;
        }
      }
    }

    &__image {
      width: 100%;
      height: 100%;

      object-fit: contain;
      object-position: 0 center;

      @include s {
        object-position: center;
      }
    }
  }
}
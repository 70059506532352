@import "styles/fonts";
@import "styles/colors";
@import "styles/sizes";

* {
  margin: 0;
  padding: 0;

  box-sizing: border-box;
  box-shadow: none;
  text-decoration: none;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: MomaSansWeb, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
}

html {
  width: 100%;
}

body {
  width: 100%;
  height: 100%;

  background-color: $grey300;

  @include s {
    background-color: $grey200;
  }
}

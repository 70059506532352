@mixin ns() {
  @media only screen and (min-width: 767px) {
    @content;
  }
}
//
//@mixin nst() {
//  @media only screen and (min-width: 1025px) {
//    @content;
//  }
//}

@mixin xs() {
  @media only screen and (min-width: 320px) and (max-width: 359px) {
    @content;
  }
}

@mixin s() {
  @media only screen and (min-width: 320px) and (max-width: 767px) {
    @content;
  }
}

@mixin tl {
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}

@mixin dsxs() {
  @media only screen and (min-width: 1025px) and (max-width: 1240px) {
    @content;
  }
}

//@mixin sm() {
//  @media only screen and (min-width: 442px) and (max-width: 767px) {
//    @content;
//  }
//}
//
//@mixin tablet() {
//  @media only screen and (min-width: 768px) and (max-width: 1024px) {
//    @content;
//  }
//}
//
//@mixin tabletUp() {
//  @media only screen and (min-width: 768px) {
//    @content;
//  }
//}
//
//@mixin smallD {
//  @media only screen and (min-width: 1025px) and (max-width: 1199px) {
//    @content;
//  }
//}
//
//@mixin md {
//  @media only screen and (min-width: 1200px) and (max-width: 1399px) {
//    @content;
//  }
//}
//
//@mixin mdl {
//  @media only screen and (min-width: 1200px) and (max-width: 1580px) {
//    @content;
//  }
//}
//
//@mixin l {
//  @media only screen and (min-width: 1400px) and (max-width: 1580px) {
//    @content;
//  }
//}
//
//@mixin xl {
//  @media only screen and (min-width: 1581px) and (max-width: 1690px) {
//    @content;
//  }
//}

//@mixin ml() {
//  @media only screen and (min-width: 576px) and (max-width: 1199px) {
//    @content;
//  }
//}
//
//@mixin sml() {
//  @media only screen and (min-width: 320px) and (max-width: 1199px) {
//    @content;
//  }
//}
//
//@mixin s() {
//  @media only screen and (min-width: 320px) and (max-width: 575px) {
//    @content;
//  }
//}
//
//@mixin m() {
//  @media only screen and (min-width: 576px) and (max-width: 991px) {
//    @content;
//  }
//}
//
//@mixin l() {
//  @media only screen and (min-width: 992px) and (max-width: 1199px) {
//    @content;
//  }
//}

@import "styles/colors";
@import "styles/sizes";

.bio {
  &__title-wrapper {
    position: relative;

    @include s {
      margin-top: 59px;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 170px;
    line-height: 97.2%;
    letter-spacing: -0.03em;
    color: $grey400;

    position: absolute;
    bottom: 4px;
    left: -9px;

    z-index: 2;

    @include s {
      font-size: 95px;
      line-height: 95px;

      left: -6px;
      bottom: -2px;
    }

    @include tl {
      font-size: 130px;
    }
  }

  &__subtitle {
    font-weight: 700;
    font-size: 72px;
    line-height: 82px;
    letter-spacing: -0.03em;
    color: $black200;

    position: relative;
    left: 3px;

    z-index: 5;

    @include s {
      font-size: 40px;
      line-height: 40px;

      left: 0;
    }

    @include tl {
      font-size: 55px;
    }
  }

  &__article {
    margin-top: 15px;

    max-width: 680px;

    @include s {
      margin-top: 28px;
    }
  }

  &__text-block {
    &:not(:last-child) {
      margin-bottom: 38px;
    }
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 38px;
    letter-spacing: -0.03em;
    color: black;

    @include s {
      font-weight: 500;
    }
  }
}